import React from "react"
import { Link } from "gatsby"

import HeroImg from "../images/icons/hero-home.svg";
import RODOISO from "../images/icons/rodo-iso.svg";

const Hero = ({ classes, title, subtitle, button, anim, gdpr, extracol, industry, industryURL }) => (
  <div className={`heroes ${classes ? classes : ''}`}>
    <div className="heroes__gradient has-text-centered has-text-left-desktop">
      <div className="heroes__gradient__inner container container--pad">
        <div class="heroes__columns columns is-multiline">
          <div class="column is-full-tablet is-half-desktop sr-hidden sr-r">
            <h1 className="heroes__title" dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && 
              <h2 className="heroes__subtitle mar-t-3" dangerouslySetInnerHTML={{ __html: subtitle }} />
            }
            {industry &&
              <Link to={industryURL} className="heroes__industry mar-t-2">{industry}</Link>
            }
            {button !== `false` &&
              <a href="/demo" class="heroes__demo button is-cta is-pink mar-t-3">
                {button !== `false` ? `Solicitar una demo` : button}
              </a>
            }
          </div>
          {anim !== `false` &&
            <div className="column is-full-tablet is-half-desktop">
              <div className="heroes__preview">
                <div className="heroes__block heroes__block--circle" />
                <div className="heroes__block heroes__block--square" />
                <div className="sr-hidden sr-b">
                  <HeroImg className="heroes__anim" />
                </div>
              </div>
            </div>
          }
          {gdpr === `yes` &&
            <div className="column is-full-tablet is-half-desktop">
              <div className="heroes__preview">
                <div className="heroes__block heroes__block--circle" />
                <div className="heroes__block heroes__block--square" />
                <div className="sr-hidden sr-r">
                  <RODOISO className="heroes__anim" />
                </div>
              </div>
            </div>
          }
          {extracol === `yes` &&
            <div className="column extracol is-full-tablet is-half-desktop sr-hidden sr-r" />
          }
        </div>
      </div>
    </div>
  </div>
)

export default Hero
